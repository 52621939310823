import { useNavigate, useLocation } from "react-router-dom"


import axios from 'axios';
import config from '../config';



class API {
    static loggedIn = () => {
        return localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== null;
    }

    static logout = async (router) => {
        console.log("LOGOUT")
        if (router.is(config.route.login)) {
            return
        }
        localStorage.removeItem('user');
        localStorage.removeItem('user_details');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        console.log("LOGOUT - NAVIGATE")
        router.navigate(config.route.login)
    };


    static login = async (router, data, errorHandler) => {
        try {
            const response = await axios.post(config.api.url+config.api.login, data);
            console.log("LOGIN", response)
            console.log("LOGIN", response.data)
            // Save user data to local storage
            localStorage.setItem('user', JSON.stringify(response.data));
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);

            API.execute(router, config.api.user.get, {}, (e)=>{console.error('Error fetching user:', e)}).then((response) => {
                console.log("USER/GET", response.data)
                localStorage.setItem('user_details', JSON.stringify(response.data));
            })

            router.navigate(config.route.portfolio);
            return response.data
        } catch (err) {
            errorHandler(err?.response?.data?.message);
        }
    }


    static execute = async (router, endpoint, data, errorHandler) => {
        if (["/risks/list"].includes(endpoint)) {
            const response = await axios.get(config.api.url+endpoint, {params: data});
            console.log("EXECUTE-DONE", response.data);
            return response.data;
        }
        console.log("EXECUTE", endpoint)
        const user = localStorage.getItem('user')
        const token = localStorage.getItem('access_token')
        if (token === undefined || token === null) {
            console.log("LOGOUT1");
            API.logout(router)
            return null
        }

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token,
                //"Access-Control-Allow-Origin": "*",
                //"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                //"Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",

            }
            const post_endpoints = ["/post/search/submit", "/tag/add", "/post/tag", "/post/add", "/user/change", "/user/create"]
            console.log("POST ENDPOINTS", endpoint, post_endpoints.includes(endpoint))
            let response = {}
            if (post_endpoints.includes(endpoint)) {
                response = await axios.post(config.api.url+endpoint, data, {headers: headers});
            } else {
                console.log("DATA", data)
                response = await axios.get(config.api.url+endpoint, {params: data, headers: headers});
            }
            console.log("RESPONSE", response);
            if (response?.status === 401) {
                console.log("LOGOUT2");
                API.logout(router)
                return null
            }
            console.log("EXECUTE-DONE", response.data);
            return response.data;
        } catch (error) {
            console.log("ERROR", error)
            if (error === null || error === undefined || error?.response?.status === 401) {
                console.log("LOGOUT3");
                API.logout(router)
                const error_msg = (error === null || error === undefined) ? "API is down" : error?.response?.data?.message
                errorHandler(error_msg)
            }
            errorHandler(error.response.data.message);
            return null
        }

    }


}


export default API;
