
class Router {
    constructor(navigate, location) {
        this.m_navigate = navigate
        this.m_location = location
    }

    navigate(path) {
        this.m_navigate(path, {replace: true});
    }

    is(path) {
        return (this.m_location.pathname === path)
    }
}

export default Router;
