
import config from "./config"
import Logo from './media/images/logo.png'
import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation } from "react-router-dom"

import {Container, Row, Col} from 'react-bootstrap';

const Footer = () => {
     let location = useLocation()
    console.log("LOCATION", location.pathname)

    return (
    <>
    <Container fluid className="footer-box">
        <Container className="p-3 mt-5">
            <footer>
            <Row className="mt-10">
                    <Col className="mb-2">
                        <img src={Logo} alt={config.pageName} />
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-2">
                        <p>© 2023 <a className='footer-link' href='https://www.sikkemainvestments.nl'>SikkemaInvestments</a> | <HashLink smooth className='footer-link' to="/landing#top">Back to Top </HashLink></p>
                    </Col>
                </Row>

            </footer>
        </Container>
    </Container>
    </>
  );
}

export default Footer
