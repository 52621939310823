
import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, useSearchParams } from "react-router-dom"
import config from "./config"
import API from './api/api'
import Formatter from './api/formatter'
import Router from './api/router'

import {

    ChartPieIcon,
    ArrowTrendingUpIcon,
    CreditCardIcon,
    CurrencyEuroIcon,
    DocumentChartBarIcon,
    Bars4Icon,
    
    XMarkIcon,
    CheckIcon,
    UserIcon,
    LockClosedIcon ,
} from '@heroicons/react/24/outline'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';

import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'


const Portfolio = () =>  {
    console.log("Overview")
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    const aspectRatio = window.screen.width / window.screen.height

    const [investments, setInvestments]   = useState([])
    const [currentInvestment, setCurrentInvestment] = useState(null)
    const [showModal, setShowModal]       = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        console.log("Overview - useEffect")
    	getInvestments();
    }, {});


    const onChange = (val) => {
    }

    const onChangeAny = (val, attr) => {
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    };

    const onClickOutside = (event) => {
        //if (modal && modal.contains(event.target)) return;
        closeModal();
    };

    const toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };


    const openModal = (investment) => {
        setCurrentInvestment(investment)
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false)
    };

    const onInvestments = (result) => {
        console.log("ON Investments")
        const data = (result.data.Target === undefined) ? result.data : result.data.Target
	    setInvestments(result.data.map((item) => (item.Target === undefined) ? item : item.Target))
    }


    const download = (investment_id) => {
	//FileDownload(investment_id)
    }

    const getInvestments = async () => {
	    console.log("getInvestments")
	    let data = API.execute(router, config.api.investment.list, {}, setErrorMessage).then((result) => {
		    if (result === null) {
		        return
		    }
		    //if (result.status !== 200) {
		    //    setErrorMessage(result.message)
		    //    return
		    //}
		    onInvestments(result)
	    })

    }

    const renderModal = () => {
        console.log("Overview - RenderModal", currentInvestment)
        if (currentInvestment === null) {
            return (<div></div>)
        }
        console.log("Overview - RenderModal")
        const urlParams   = "?investment_id="+currentInvestment.investment_id

        return (
    	    <Modal
                show={showModal}
                onHide={closeModal}
                centered>
                <Row>
	            	<Col md="10">
		<Modal.Header>
                            <Modal.Title>{currentInvestment.title}</Modal.Title>
		</Modal.Header>
    		        </Col>
                <Col md="2" className="align-right modal-button clickable" onClick={closeModal}>
		<table width="100%"><tbody width="100%">
                <tr>
                <td className="modal-col">Close</td>
                <td className="modal-col"><XMarkIcon className="w-5 h-9 text-black-900" alt="Close"/></td>
                </tr>
		</tbody></table>
                </Col>
	    	    </Row>

            <Modal.Body class="modal-body">
                <Row><Col md="12"><table width="100%"><tbody width="100%">
                    <tr>
                        <td className="modal-col"> Client </td>
                        <td className="modal-col">{currentInvestment.client}</td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Amount </td>
                        <td className="modal-col">{Formatter.amount(currentInvestment.amount)}</td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Committed </td>
                        <td className="modal-col">{Formatter.amount(currentInvestment.committed)}</td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Uncommitted </td>
                        <td className="modal-col">{Formatter.amount(currentInvestment.uncommitted)}</td>
                    </tr>
                </tbody></table></Col></Row>
            </Modal.Body>
            <Modal.Footer>
                <Row><Col md="12"><table width="100%"><tbody width="100%">
                    <tr>
                        <td className="modal-col">
	                        <div>
		                    </div>
  		                </td>
                        <td className="modal-col">
	                        <div className="text-center clickable" onClick={()=>{router.navigate(config.route.dashboard+urlParams)}}>
		                        <ChartPieIcon alt="Dashboard"/>
		                    </div>
  		                </td>
                        <td className="modal-col">
	                        <div className="text-center clickable" onClick={()=>{router.navigate(config.route.pnl+urlParams)}}>
		                        <ArrowTrendingUpIcon alt="PNL"/>
		                    </div>
  		                </td>
                        <td className="modal-col">
		                    <div className="text-center clickable" onClick={()=>{router.navigate(config.route.cashflow+urlParams)}}>
		                        <CurrencyEuroIcon alt="Cashflow"/>
		                    </div>
  		                </td>
  		            </tr>
                    <tr>
                        <td className="modal-col">
	                        <div>
		</div>
  		                </td>
                        <td className="modal-col">
	                        <div className="text-center clickable" onClick={()=>{router.navigate(config.route.pnl+urlParams)}}>
		                        PNL
		                    </div>
  		                </td>
                        <td className="modal-col">
		                    <div className="text-center clickable" onClick={()=>{router.navigate(config.route.cashflow+urlParams)}}>
		                        Cashflow
		                    </div>
  		                </td>
  		            </tr>
                    <tr>
                        <td className="modal-col">
    		<div className="text-center clickable" onClick={()=>{download(currentInvestment.investment_id)}}>
	    	                    <Bars4Icon alt="Loantape"/>
		                    </div>
  		                </td>
                        <td className="modal-col">
    		                <div className="text-center clickable" onClick={()=>{router.navigate(config.route.covenant+urlParams)}}>
	    	                    <CreditCardIcon alt="Covenants"/>
		                    </div>
  		                </td>
                        <td className="modal-col">
    		                <div className="text-center clickable" onClick={()=>{router.navigate(config.route.defaults+urlParams)}}>
	    	                    <DocumentChartBarIcon alt="Defaults"/>
		                    </div>
    		            </td>
                    </tr>
                    <tr>
                        <td className="modal-col">
    		<div className="text-center clickable" onClick={()=>{download(currentInvestment.investment_id)}}>
		                        Loantape
		                    </div>
  		                </td>
                        <td className="modal-col">
	                        <div className="text-center clickable" onClick={()=>{router.navigate(config.route.pnl+urlParams)}}>
		                        Covenants
		                    </div>
  		                </td>
                        <td className="modal-col">
		                    <div className="text-center clickable" onClick={()=>{router.navigate(config.route.cashflow+urlParams)}}>
		                        Defaults
		                    </div>
  		                </td>
  		            </tr>
                </tbody></table></Col></Row>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderCell = (label, value) => {
        const activeClass = "w-5 h-9 text-black-900"
	if (aspectRatio < 1) {

	} else {

	}

    }

    const renderInvestment = (investment) => {
        const activeClass = "w-9 h-9 text-black-900 center"
        const urlParams   = "?investment_id="+investment.investment_id
        console.log("Overview - RenderInvestment")
        console.log("Overview - investment", investment)
        return (
	    	<Row className="investment-content">
		<Col md="2" className="clickable" onClick={(event)=>openModal(investment)}>
		<p>
                {investment.client}
	    </p>
                </Col>
                <Col md="1" className="clickable" onClick={(event)=>openModal(investment)}>
		<div className="center justify-content-center">Total Investment</div>
                <div className="center justify-content-center">{Formatter.amount(investment.amount)}</div>
                </Col>
        	<Col md="1" className="clickable" onClick={(event)=>openModal(investment)}>
		<div className="center justify-content-center">Net IRR</div>
                <div className="center justify-content-center">{investment.irr.toFixed(2)}%</div>
                </Col>
        	<Col md="1" className="clickable" onClick={(event)=>openModal(investment)}>
		<div className="center justify-content-center">Current PNL</div>
                <div className="center justify-content-center">{investment.pnl}</div>
                </Col>
		<Col md="1" className="text-center clickable" onClick={()=>{router.navigate(config.route.dashboard+urlParams)}}>
		<div className="center justify-content-center">Dashboard</div>
                <div className="center justify-content-center"><ChartPieIcon alt="Dashboard" className={activeClass}/></div>
                </Col>
		<Col md="1" className="text-center clickable" onClick={()=>{router.navigate(config.route.pnl+urlParams)}}>
		<div className="center justify-content-center">PNL</div>
                <div className="center justify-content-center"><ArrowTrendingUpIcon alt="PNL" className={activeClass}/></div>
                </Col>
		<Col md="1" className="text-center clickable" onClick={()=>{router.navigate(config.route.cashflow+urlParams)}}>
		<div className="center justify-content-center">Cashflow</div>
                <div className="center justify-content-center"><CurrencyEuroIcon alt="Cashflow" className={activeClass}/></div>
                </Col>
		<Col md="1" className="text-center clickable" onClick={()=>{router.navigate(config.route.covenants+urlParams)}}>
		<div className="center justify-content-center">Covenants</div>
                <div className="center justify-content-center"><CreditCardIcon alt="Covenants" className={activeClass}/></div>
                </Col>
		<Col md="1" className="text-center clickable" onClick={()=>{router.navigate(config.route.defaults+urlParams)}}>
		<div className="center justify-content-center">Defaults</div>
                <div className="center justify-content-center"><DocumentChartBarIcon alt="Defaults" className={activeClass}/></div>
                </Col>
		<Col md="1" className="text-center clickable" onClick={()=>{download(investment.investment_id)}}>
		<div className="center justify-content-center">Loantape</div>
                <div className="center justify-content-center"><Bars4Icon alt="Loantape" className={activeClass}/></div>
                </Col>
		</Row>
	)
    }

    console.log("Overview - RENDER")
    console.log("Overview - INVESTMENTS", investments)


    return (
        <>
		<Helmet>
		  <title>Current Investments</title>
		</Helmet>
        <div section className="block text-center investment-box col-md-10">
            <div className="investment-list">
                {investments.map((message, index) => renderInvestment(message))}
            </div>
            <div className="investment-list">
                {renderModal()}
            </div>
        </div>
        </>
	)

}



export default Portfolio



