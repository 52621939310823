const config = {
    pageName: "A-DCM Risk Management",
    
    route: {
	    //landing: "http://localhost:6110",
	    landing: "https://adcm.sikkemasoftware.nl",
	    base:    "/",
	    landing: "/landing",
	    login:   "/login",
	    profile: "/profile",
	    reset:   "/reset",
	    resetSubmit: "/resetSubmit",
	    covenants: "/covenants",
	    cashflow:   "/cashflow",
	    dashboard:  "/dashboard",
	    pnl:        "/pnl",
	    defaults:   "/defaults",
	    overview:   "/overview",
	    portfolio:   "/portfolio",
    },
    api: {
    	//url:       'http://127.0.0.1:6210/api/v01',
    	url:       'https://api.sikkemasoftware.nl:6210/api/v01',
	    login:      '/user/login',
	    investment: {
	        list:        '/investment/list',
	    },
	    risks: {
	        list:        '/risks/list',
	    },
	    user: {
	        get:    '/user/get'
	    },
	    pnl: {
	        list:    '/pnl/list',
	    },
	    cashflow: {
	        list:  '/cashflow/list',
	    },
	    covenant: {
	        list:  '/covenant/list',
	    },
    	defaults: {
	        list:  '/defaults/list',
	    },
    },
};
  

export default config;


