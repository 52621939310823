
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import API from '../api/api'
import Router from '../api/router'
import config from "../config"

import { CheckIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";

import Logo from '../media/images/logo.png'

const Login = () => {
    const navigate = useNavigate()
	const location = useLocation();
	const router   = new Router(navigate, location)

    console.log('Login')
    const [credentials, setCredentials]   = useState({username: "", password: ""})
    const [errorMessage, setErrorMessage] = useState("")

    const is_login = location.pathname === config.route.login


	const onChange = (e) => {
        e.preventDefault()
        setCredentials(prevState => ({...prevState, [e.target.name]: e.target.value}))
        setErrorMessage("")
    }

	const onError = (err) => {
        setErrorMessage(err)
	}

	const onLogin = async (e) => {
	    console.log("ONLOGIN")
	    API.login(router, credentials, onError)
	}

  const Logon = () => {
    const filled = (credentials.username && credentials.password)
    const location = useLocation()
    console.log("Login-Location", location)
    const active = location === config.route.login
	return (
	  <>
       		<div className={"mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full"+(filled ? " bg-green-300 ": " bg-gray-300 ")}>
	  		    <LockClosedIcon className={"h-8 w-8 "+(is_login ? " text-green-700 " : " text-gray-500 ")} aria-hidden="true" />
            </div>
            <div className="mt-3 text-center ">
                <div className="text-3xl font-medium text-gray-900">
                    Log in
                </div>
				<p className="text-lg text-gray-500">
				    Login to your account
				</p>
				<div className="flex flex-col flex-1">
					<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Username</label>
					<input value={credentials.username} name="username" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
				</div>
				<div className="flex flex-col flex-1">
					<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
	    			<input value={credentials.password} name="password" onChange={(e)=>onChange(e)} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="*******" />
				</div>
				<div className="flex flex-col">
					<button type="submit" onClick={() => onLogin()} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
						Log in
					</button>
					<a href={'./reset'} className="mt-4 text-gray-400 text-sm">Forgot your password?</a>
				</div>
            </div>
	  </>
	)
  }

    console.log("Login - RENDER")
	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">

			<div className="min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2">
              <div className="px-4 py-4 md:px-12 md:py-12">
				{(is_login) ? Logon() : ""}
				{errorMessage ? <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
				{errorMessage}
				</div> : null}
				</div>
				<a href={config.landingPageUrl} className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer">
					Back home
				</a>
			</div>
		</div>
		</>)

}



export default Login
