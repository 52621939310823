

class Formatter {
    // Class for parsing json files
    static amount = (amount, digits=2) => {
        const amount_abs = Math.abs(amount)
        if (amount_abs < 100) {
            return amount.toFixed(digits)
        }
        if (amount_abs < 100000) {
            return (amount/1000).toFixed(digits)+"k"
        }
        if (amount_abs < 1000000000) {
            return (amount/1000000).toFixed(digits)+"mln"
        }
        if (amount_abs < 1000000000000) {
            return (amount/1000000000).toFixed(digits)+"bln"
        }
        return amount.toFixed(digits)
    }
}


export default Formatter;
