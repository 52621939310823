import { React, useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import config from "./config"
import API from './api/api'
import Formatter from './api/formatter'
import Map from './Components/Map'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {Scatter} from 'react-chartjs-2';

import {

    ShoppingBagIcon,
    BuildingOffice2Icon,
    HomeModernIcon,
    BeakerIcon,
    BoltIcon,
    TruckIcon,
    UserGroupIcon,
    Cog8ToothIcon,
    AcademicCapIcon,
    BanknotesIcon,
    FilmIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'



const Overview = () =>  {
    console.log("Portfolio")
    const navigate = useNavigate()
    const location = useLocation()

    const [currentInvestment, setCurrentInvestments] = useState(null)
    const [showModal, setShowModal]       = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [investments, setInvestments]   = useState([])

    useEffect(() => {
        console.log("Portfolio - useEffect")
    }, {});


    const onChange = (val) => {
    }

    const onChangeAny = (val, attr) => {
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    };

    const onClickOutside = (event) => {
        //if (modal && modal.contains(event.target)) return;
        closeModal();
    };

    const toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };


    const openModal = (investment, investments) => {
        setCurrentInvestments(investment)
        setInvestments(investments)
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false)
    };

    const renderSector = (sectors) => {
        const activeClass = "w-5 h-9 text-black-900"
        const inactiveClass = "w-5 h-9 text-green-400"
        console.log("Sector", sectors)
        return (
            <>
            <Row><Col md="12"><table width="100%"><tbody width="100%">
            <tr>
	        	<td>
                    <ShoppingBagIcon     className={sectors.includes(1)  ? activeClass : inactiveClass} alt="Consumer Credit"/>
                </td>
	        	<td>
                    <BuildingOffice2Icon className={sectors.includes(2)  ? activeClass : inactiveClass} alt="Commercial Real Estate"/>
                </td>
	        	<td>
                    <HomeModernIcon      className={sectors.includes(3)  ? activeClass : inactiveClass} alt="Consumer Real Estate"/>
                </td>
	        	<td>
                    <AcademicCapIcon     className={sectors.includes(4)  ? activeClass : inactiveClass} alt="Education"/>
                </td>
            </tr>
            <tr>
	        	<td>
                    <BeakerIcon          className={sectors.includes(5)  ? activeClass : inactiveClass} alt="Chemical Industry"/>
                </td>
	        	<td>
                    <Cog8ToothIcon       className={sectors.includes(6)  ? activeClass : inactiveClass} alt="Engineering"/>
                </td>
	        	<td>
                    <BoltIcon            className={sectors.includes(7)  ? activeClass : inactiveClass} alt="Utilities"/>
                </td>
	        	<td>
                    <TruckIcon           className={sectors.includes(8)  ? activeClass : inactiveClass} alt="Shipping"/>
                </td>
            </tr>
            <tr>
	        	<td>
                    <BanknotesIcon       className={sectors.includes(9)  ? activeClass : inactiveClass} alt="Finance"/>
                </td>
	        	<td>
                    <UserGroupIcon       className={sectors.includes(10)  ? activeClass : inactiveClass} alt="Healthcare"/>
                </td>
	        	<td>
                    <FilmIcon            className={sectors.includes(11) ? activeClass : inactiveClass} alt="Entertainment"/>
                </td>
	        	<td>
                </td>
           </tr>
           </tbody></table></Col></Row>
            </>
        )
    }

    const renderChart = () => {
           ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
           const options = {scales: {
                x: {beginAtZero: true,
                    text: "Risk",
                    display: true,},
                y: {beginAtZero: true,
                    text: "Return",
                    display: true,},
	       responsive: true,
	       maintainAspectRatio: true,
	       aspectRatio: 1,
            },
            };

            //const data = {
            //    datasets: investments.map((item) => {
            //        return {label: item.client,
            //                x: item.risks["0"],
            //                y: item.irr}})
            //};
            const data = {datasets:
		[{label: currentInvestment.client,
                 data: [{label: currentInvestment.client,
                            x: currentInvestment.risks["0"],
                            y: currentInvestment.irr}],
                 backgroundColor: 'blue'},
			      {label: "Risk/Return",
                 data: investments.filter((item)=>item.investment_id !== currentInvestment.investment_id).map((item) => {
                    return {label: item.client,
                            x: item.risks["0"],
                            y: item.irr}}),
                 backgroundColor: 'red'},
                 ]}
            console.log(data)

          return (<>
            <Scatter options={options} data={data} />
            </>);

    }


    const renderModal = () => {
        console.log("Portfolio - RenderModal", currentInvestment)
        if (currentInvestment === null) {
            return (<div> </div>)
        }
        console.log("Portfolio - RenderModal")

        return (
    	    <Modal
                show={showModal}
                onHide={closeModal}
            centered
	    className="modal"
                >
            <Row>
	        	<Col md="10">
                    <Modal.Header>
                        <Modal.Title>{currentInvestment.client}</Modal.Title>
                    </Modal.Header>
		        </Col>
                <Col md="2" className="align-right modal-button clickable" onClick={closeModal}>
		<table width="100%"><tbody width="100%">
                <tr>
                <td className="modal-col">Close</td>
                <td className="modal-col"><XMarkIcon className="w-5 h-9 text-black-900" alt="Close"/></td>
                </tr>
		</tbody></table>
                </Col>
		    </Row>


            <Modal.Body className="modal-body">
                <Row><Col md="12"><table width="100%"><tbody width="100%">
                <tr>
                    <td className="modal-col"> Client </td>
                    <td className="modal-col">{currentInvestment.client}</td>
                </tr>
                <tr>
                    <td className="modal-col"> Amount </td>
                    <td className="modal-col">{Formatter.amount(currentInvestment.amount)}</td>
        		</tr>
                <tr>
                    <td className="modal-col"> Committed </td>
                    <td className="modal-col">{Formatter.amount(currentInvestment.committed)}</td>
        		</tr>
                <tr>
                    <td className="modal-col"> Uncommitted </td>
                    <td className="modal-col">{Formatter.amount(currentInvestment.uncommitted)}</td>
        		</tr>
                <tr>
                    <td className="modal-col"> Default Risk </td>
                    <td className="modal-col">{currentInvestment.risks[1].toFixed(2)}%</td>
        		</tr></tbody></table></Col></Row>
                <Row>
                    <Col md="12" className="modal-col"> Sectors </Col>
                </Row>
                <Row>
                    <Col md="12" className="modal-col">
                        <div>
                           {renderSector(currentInvestment.sectors)}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="modal-col">
                        <div className="chart-container">
                            {renderChart()}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                </Modal.Footer>
            </Modal>
        )
    }

    console.log("Portfolio - RENDER")
    const center = {latitude: 52.3643713,
		    longitude: 4.8895328}
    //const center = {latitude: 52.3643713,
    //longitude: -30.8895328}

    return (
        <>
		<Helmet>
		  <title>Current Investments</title>
		</Helmet>
		<div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
		    <div className="text-center mb-6">
		        <Map coordinates={center}
		             display_name="Current Investments"
		             setModal={openModal}
		             errorHandler={setErrorMessage}
                />
		    </div>
		</div>
        <div section className="block text-center investment-box col-md-10">
            <div className="investment-list">
                {renderModal()}
            </div>
        </div>
        </>
	)

}


export default Overview
