

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import React from "react";
import { FlagIcon } from '@heroicons/react/24/outline'
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

import L from "leaflet";
import API from '../api/api'
import Router from '../api/router'
import config from '../config'




const Map = (params) => {
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    const [settings, setSettings] = useState({coordinates: {latitude: 0.0, longitude: 0.0},
                                              display_name: "",
                                              setModal: null,
                                              errorHandler: null})

    console.log("LOCATION", settings.coordinates.latitude, settings.coordinates.longitude)

    const [investments, setInvestments]   = useState([])


    useEffect(() => {
        console.log("Portfolio - useEffect")
        console.log("PARAMS", params)
    	settings.coordinates = params.coordinates
        settings.display_name = params.display_name
        settings.setModal     = params.setModal
        settings.errorHandler = params.errorHanlder

    	getInvestments();

    }, {});


    const onInvestments = (result) => {
        const data = (result.data.Target === undefined) ? result.data : result.data.Target
        const tmp  = result.data.map((item) => (item.Target === undefined) ? item : item.Target)
        const tmp2 = tmp.map((item) => {return {
            investment_id: item.investment_id,
	        location: JSON.parse(JSON.parse(item.location)),
	        risks: JSON.parse(JSON.parse(item.risks)),
	        sectors: JSON.parse(JSON.parse(item.sectors)),
	        client: item.client,
	        amount: item.amount,
	        committed: item.committed,
	        uncommitted: item.uncommitted,
	        irr: item.irr,
	    }})
	    console.log("ONINVESTMENTS", tmp2)
	    setInvestments(tmp2)
    }

    const getInvestments = async () => {
	    console.log("getInvestments")
	    let data = await API.execute(router, config.api.risks.list, {}, settings.errorHandler).then((result) => {
	        console.log("GetInvestments", result)
		    if (result === null) {
		        settings.errorHandler("API Unavailable")
		        return
		    }
		    //if (result.status !== 200) {
		    //    errorHandler(result.message)
		    //    return
		    //}
		    onInvestments(result)
	    })

    }




  const customIcon = new L.Icon({//creating a custom icon to use in Marker
    iconUrl: FlagIcon,
    iconSize: [25, 35],
    iconAnchor: [5, 30]
  });

  function MapView() {
    let map = useMap();
    map.setView([settings.coordinates.latitude, settings.coordinates.longitude], map.getZoom());
     //Sets geographical center and zoom for the view of the map
    return null;
  }

  return (
    <MapContainer
      classsName="map"
      center={[settings.coordinates.latitude, settings.coordinates.longitude]}
      zoom={2.5}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> 
        contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {investments.map((investment, index) =>
          <Marker position={[investment.location.lat, investment.location.lon]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})} >
            <Popup><div>{investment.client}</div><div onClick={(e) => {settings.setModal(investment, investments)}} className="clickable">Details</div> </Popup>
          </Marker>

      )}
      <MapView />
    </MapContainer>
  );
}


export default Map;
