
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import config from "./config"
import API from './api/api'
import Router from './api/router'

import {

    ChartPieIcon,
    CreditCardIcon,
    CurrencyEuroIcon,
    DocumentChartBarIcon,

    CheckIcon,
    UserIcon,
    LockClosedIcon ,
} from '@heroicons/react/24/outline'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';

import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'


const CashFlow = () =>  {
    console.log("CASHFLOW")
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)

    const [investments, setInvestments]   = useState([])
    const [selection,   setSelection]   = useState({aggregation: "year"})
    const [errorMessage, setErrorMessage] = useState("")

    const [searchParams, setSearchParams] = useSearchParams();
    const investment_id = searchParams.get('investment_id')

    useEffect(() => {
        console.log("Overview - useEffect", investment_id)
    	getInvestments(investment_id);
    }, [investment_id, selection]);


    const onChange = (val) => {
        setSelection(prevState => ({...prevState, [val.target.name]: val.target.value}))
   }

    const onChangeAny = (val, attr) => {
    }

    const onKeyDown = (event) => {
    };

    const onInvestments = (result) => {
        console.log("ON Investments", result.data)
        console.log("ON Investments", result.keys)
        console.log("ON Investments", result.months)
        //const data_raw = (result.data.Target === undefined) ? result.data : result.data.Target
        //const keys_raw = (result.keys.Target === undefined) ? result.keys : result.keys.Target
        //const data   = data_raw.map((item) => (item.Target === undefined) ? item : item.Target)
        //const months = result.months.map((item) => (item.Target === undefined) ? item : item.Target)
        //const keys   = result.keys.map((item) => (item.Target === undefined) ? item : item.Target)
        const data_clean = {
            months: result.months,
            keys: result.keys,
            data: result.data,
        }
        console.log(data_clean)
  	    setInvestments(data_clean)

    }

    const getInvestments = async (investment_id) => {
	    console.log("getInvestments")
	    const query = {
            investment_id: investment_id,
            aggregation:   selection.aggregation,
            page_size: 1000,
	    }

	    let data = API.execute(router, config.api.cashflow.list, query, setErrorMessage).then((result) => {
	        console.log("GetInvestments - Result", result)
		    if (result === null) {
		        return
		    }
		    onInvestments(result)
	    })

    }

    const formatAmount = (amount) => {
        if (amount === "") {
            return ""
        }
         const formatting_options = {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
         }
         // users can see how locale passed as a parameter.
         return new Intl.NumberFormat("en-IE", formatting_options).format(amount)
    }


    const renderInvestments = () => {
        if (investments===undefined || investments===null || investments.length === 0) {
            return (<></>)
        }
        const activeClass = "w-5 h-9 text-black-900"
        console.log("Overview - RenderInvestment")
        console.log("Overview - investment", investments)

        return (
            <Row className="investment-content">
		        <Col md="12">
		        <table>
		            <tr><th></th>
		                {investments.months.map((month, i) => {
		                    return (<th>{month}</th>)
		                })}
		            </tr>
		            <tbody>

		            {investments.keys.map((key, index) => {
		                const next_key = investments.keys[Math.min(investments.keys.length, index+1)]
		                const is_highlight = next_key === "" && key !== ""
		                const key_class = (is_highlight) ?  "pnl-keys-highlight" : "pnl-keys"
		                const data_class = (is_highlight) ? "pnl-highlight" : "pnl"
		                if (key === "") {
		                    return (<tr><td></td></tr>)
		                } else {
                            return (<tr><td className={key_class}>{key}</td>
        		                {investments.months.map((month) => {
        		               return (<td class={data_class}>{formatAmount(investments.data[month][key])}</td>)})}
                            </tr>)

		                }
		            })}
                </tbody></table>
                </Col>
            </Row>
	)
    }

    console.log("Overview - RENDER")
    console.log("Overview - INVESTMENTS", investments)


    return (
        <>
		<Helmet>
		  <title>Current Investments</title>
		</Helmet>
        <div section className="block text-center investment-box col-md-10">
            <div className="investment-list">
                <select onChange={onChange} name="aggregation" value={selection.aggregation}>
                    {["year", "quarter", "month"].map((key) => (
                        <option key={key} name="aggregation" value={key} className="form-control">
                            {key}
                        </option>
                    ))}
                </select>
            </div>
            <div className="investment-list">
                {renderInvestments()}
            </div>
        </div>
        <div section className="block text-center investment-box col-md-10">
            <div className="investment-list">
            </div>
        </div>
        </>
	)

}



export default CashFlow



