
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import config from "./config"
import API from './api/api'
import Router from './api/router'

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

import {

    ChartPieIcon,
    CreditCardIcon,
    CurrencyEuroIcon,
    DocumentChartBarIcon,

    CheckIcon,
    UserIcon,
    LockClosedIcon ,
} from '@heroicons/react/24/outline'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';

import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'


const Dashboard = () =>  {
    console.log("Dashboard")
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)

    const [investments, setInvestments]   = useState([])
    const [selection,   setSelection]   = useState({aggregation: "year"})
    const [errorMessage, setErrorMessage] = useState("")

    const [searchParams, setSearchParams] = useSearchParams();
    const investment_id = searchParams.get('investment_id')

    useEffect(() => {
        console.log("Overview - useEffect", investment_id)
    	getInvestments(investment_id);
    }, [investment_id, selection]);


    const onChange = (val) => {
        setSelection(prevState => ({...prevState, [val.target.name]: val.target.value}))
   }

    const onChangeAny = (val, attr) => {
    }

    const onKeyDown = (event) => {
    };

    const onInvestments = (result) => {
        const data_clean = {
            months: result.months,
            keys: result.keys,
            data: result.data,
        }
        console.log(data_clean)
  	    setInvestments(data_clean)

    }

    const getInvestments = async (investment_id) => {
	    const query = {
            investment_id: investment_id,
            aggregation:   selection.aggregation,
            page_size: 1000,
	    }

	    let data = API.execute(router, config.api.pnl.list, query, setErrorMessage).then((result) => {
		    if (result === null) {
		        return
		    }
		    onInvestments(result)
	    })

    }

    const formatAmount = (amount) => {
        if (amount === "") {
            return ""
        }
         const formatting_options = {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
         }
         // users can see how locale passed as a parameter.
         return new Intl.NumberFormat("en-IE", formatting_options).format(amount)
    }


    const renderChartPNL = () => {
        if (investments===undefined || investments===null || investments.length === 0) {
            return (<></>)
        }

	ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
        const options = {scales: {
            x: {beginAtZero: false,
                text: "Date",
                display: true,},
            y: {beginAtZero: true,
                text: "PNL (%)",
                display: true,},
	    responsive: true,
	    maintainAspectRatio: true,
	    aspectRatio: 2,
        }};

	const data = {
	    labels: investments.months,
	    datasets: [{
		label: "PNL",
		data: investments.months.map((month)=>investments.data[month].pnl),
		fill: false,
		backgroundColor: "blue",
		borderColor: "blue",
	    },]
	}

        console.log(data)
	
        return (<>
		<Line options={options} data={data} />
		</>);
    }


    const renderChartDefaults = () => {
        if (investments===undefined || investments===null || investments.length === 0) {
            return (<></>)
        }

	ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
        const options = {scales: {
            x: {beginAtZero: false,
                text: "Date",
                display: true,},
            y: {beginAtZero: true,
                text: "PNL (%)",
                display: true,},
	    responsive: true,
	    maintainAspectRatio: true,
	    aspectRatio: 2,
        }};

	const data = {
	    labels: investments.months,
	    datasets: [{
		label: "PNL",
		data: investments.months.map((month)=>investments.data[month].pnl),
		fill: false,
		backgroundColor: "blue",
		borderColor: "blue",
	    },]
	}

        console.log(data)
	
        return (<>
		<Line options={options} data={data} />
		</>);
    }


    console.log("Overview - RENDER")
    console.log("Overview - INVESTMENTS", investments)


    return (
        <>
		<Helmet>
		  <title>Current Investments - Dashboard</title>
		</Helmet>
        <div section className="block text-center investment-box col-md-10">
            <div className="investment-list">
                <select onChange={onChange} name="aggregation" value={selection.aggregation}>
                    {["year", "quarter", "month"].map((key) => (
                        <option key={key} name="aggregation" value={key} className="form-control">
                            {key}
                        </option>
                    ))}
                </select>
            </div>
            <div className="investment-list">
	    <Row>
	    <Col className="md-6">
	    {renderChartPNL()}
	    </Col>
	    <Col className="md-6">

	    </Col>
            </Row>
	    <Row>
	    <Col className="md-6">

	    </Col>
	    <Col className="md-6">

	    </Col>
            </Row>
            </div>
        </div>
        </>
	)

}



export default Dashboard



