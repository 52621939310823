
import { Helmet } from "react-helmet";
import { Row, Col, Container, Form } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import config from "../config"
import Logo from '../media/images/logo.png'

const Landing = () => {
  // Currently Selected Input Option
    console.log("Landing")
  return (
      <>
        <Helmet>
          <title>{"About - "+config.pageName}</title>
        </Helmet>

    <Container fluid className="whitebk">
        <Container className="p-3 mt-5">
            <Row>
                <Col md={7}>
                    <h1 className='mt-desktop'>A-DCM Risk Management Portal</h1>
                    <br /><br />
                    <p> - Please login to access the portfolio of your company with A-DCM</p>
                    <p> - Contact A-DCM for your credentials or more information</p>
                </Col>
            </Row>
        </Container>
    </Container>


      </>)

}



export default Landing